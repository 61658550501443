const MenuData = [
    {
        name: 'About',
        route: '/about'
    },
    {
        name: 'Projects',
        route: '/projects'
    },
    {
        name: 'Resume',
        route: '/resume'
    },
    {
        name: 'Blog',
        route: '/blog'
    },
]

export default MenuData;